import MarkdownIt from 'markdown-it';
import MarkdownItAttrs from 'markdown-it-attrs';

export default () => {
  const md = new MarkdownIt({
    html: true,
    breaks: true,
    linkify: false,
  });

  md.use(MarkdownItAttrs, {
    // optional, these are default options
    leftDelimiter: '{',
    rightDelimiter: '}',
    allowedAttributes: ['target', 'rel'],
  });

  const defaultImageRenderer = md.renderer.rules.image;
  // add lazy loading to images
  md.renderer.rules.image = (tokens, idx, options, env, self) => {
    const token = tokens[idx];
    const srcIndex = token.attrIndex('src');
    if (srcIndex >= 0) {
      const src = token.attrs[srcIndex][1];
      token.attrs.push(['loading', 'lazy']);
      token.attrs.push(['decoding', 'async']);
    }

    return defaultImageRenderer(tokens, idx, options, env, self);
  };

  return md;
};
